<template>
  <div class="theme-new-wrapper">
    <header>
      <h3>{{ currentTabTitle }}</h3>
    </header>
    <main>
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                v-if="isSaveButtonVisible"
                type="button"
                :class="['btn', 'btn-success', { 'btn-disabled': isSaveButtonDisabled }]"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <ButtonClose route-name="themeSpecialAndSeries_list" />
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-12">
                    <header class="themeSpecialAndSeries-info-header">
                      <div>
                        <h6> {{ $t('tag.geneeaTag.generalInfo.createdAt') }}: </h6>
                        <span> {{ theme.createdAt | prettyDateTime }} </span>
                      </div>
                      <div>
                        <h6> {{ $t('tag.geneeaTag.generalInfo.modifiedAt') }}: </h6>
                        <span> {{ theme.modifiedAt | prettyDateTime }} </span>
                      </div>
                    </header>
                    <main>
                      <div class="row">
                        <!-- *** First col *** -->
                        <div class="col-lg-6 theme-first-col">
                          General information
                          <div class="theme-generalInfo">
                            <Checkbox
                              v-model="theme.isActive"
                              id="special_isActive"
                              :label="$t('themeSpecialAndSeries.isActive')"
                              class="m-t-10 m-b-20"
                            />
                            <Checkbox
                              v-model="theme.forChildSites"
                              id="theme_all_sites_on"
                              :label="$t('themeSpecialAndSeries.specials.allSitesCheckboxLabel')"
                              class="m-t-10 m-b-20"
                            />
                            <SelectInput
                              v-model="theme.siteId"
                              id="theme_site_select"
                              optionTitle="title"
                              :options="sites"
                              :noEmptyValue="false"
                              :required="false"
                              :label="$t('themeSpecialAndSeries.specials.sitesInputLable') + ' *'"
                              :disabled="theme.forChildSites"
                              @blur="$v.theme.siteId.$touch()"
                              :error="$v.theme.siteId.$error"
                            />
                            <Input
                              v-model="theme.name"
                              :label="$t('themeSpecialAndSeries.specials.name') + ' *'"
                              @blur="$v.theme.name.$touch()"
                              :error="$v.theme.name.$error || formErrors.type === 'duplicate_name'"
                            />
                            <Input
                              v-if="editMode"
                              v-model="theme.id"
                              label="Id"
                              disabled
                            />
                            <Input
                              v-model="theme.slug"
                              :label="$t('themeSpecialAndSeries.specials.slug') + ' *'"
                              @blur="$v.theme.slug.$touch()"
                              :error="$v.theme.slug.$error"
                            />
                            <div class="title-text">
                              {{ $t('themeSpecialAndSeries.specials.description') }}
                            </div>
                            <RichTextEditor
                              v-model="theme.description"
                              :other-options="richTextEditorConfig"
                              id="geneeaTag_description"
                              class="mb-3"
                            />
                            <h3> {{ $t('themeSpecialAndSeries.specials.metaHeader') }} </h3>
                            <Input
                              v-model="theme.meta.title"
                              :label="$t('themeSpecialAndSeries.specials.meta.title')"
                            />
                            <Input
                              v-model="theme.meta.description"
                              :label="$t('themeSpecialAndSeries.specials.meta.description')"
                            />
                            <Input
                              v-model="theme.meta.keywords"
                              :label="$t('themeSpecialAndSeries.specials.meta.keywords')"
                            />
                          </div>
                        </div>
                        <!-- *** Second col *** -->
                        <div class="col-lg-6 geenea-tag-second-col">
                          <!-- event -->
                          <div class="theme-generalInfo">
                            <div class="title-text">
                              {{ $t('themeSpecialAndSeries.specials.heroImage') }}
                            </div>
                            <ModuleFormPhotoBox
                              :image="theme.heroImage"
                              :media-usage-type="themeMediaTypes.hero"
                              hide-separator
                              @set-media="setSpecialheroImage"
                              @remove-media="removeSpecialheroImage"
                              class="mb-3"
                            />
                            <div class="title-text">
                              {{ $t('themeSpecialAndSeries.specials.listingImage') }}
                            </div>
                            <ModuleFormPhotoBox
                              :image="theme.listingImage"
                              :media-usage-type="themeMediaTypes.listing"
                              hide-separator
                              @set-media="setSpecialListingImage"
                              @remove-media="removeSpecialListingImage"
                              class="mb-3"
                            />
                          </div>
                        </div>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  </div>
</template>

<script>

import PermissionService from '@/services/PermissionService'
import { mapGetters, mapState } from 'vuex'
import Input from '@/components/form/inputs/Input.vue'
import ButtonClose from '@/components/shared/ButtonClose.vue'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox.vue'
import SelectInput from '@/components/form/select/Select.vue'
import Checkbox from '@/components/form/Checkbox.vue'
import TinyMceConfig from '@/services/tinymce/TinyMceConfig'
import NotifyService from '@/services/NotifyService'
import ThemeModel from '@/model/ThemeModel'
import TinyMceConfigVLMSettings from '@/model/TinyMceConfigVLMSettings'
import { MEDIA_USAGE_TYPE_THEME_HERO, MEDIA_USAGE_TYPE_THEME_LISTING } from '@/model/ValueObject/MediaUsageTypes'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'

const notZeroNullOrUndefined = value => value !== 0 && value !== null && value !== undefined

export default {
  name: 'ThemeNew',
  props: {
    editMode: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Checkbox,
    SelectInput,
    ModuleFormPhotoBox,
    RichTextEditor,
    ButtonClose,
    Input
  },
  data () {
    return {
      theme: this._.cloneDeep(ThemeModel),
      themeMediaType: '',
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_TAG_PERMISSIONS,
      richTextEditorConfig: {
        ...TinyMceConfig.getConfig(), ...TinyMceConfigVLMSettings
      },
      themeMediaTypes: {
        hero: MEDIA_USAGE_TYPE_THEME_HERO,
        listing: MEDIA_USAGE_TYPE_THEME_LISTING
      }
    }
  },
  validations: {
    theme: {
      siteId: {
        required,
        notZeroNullOrUndefined
      },
      slug: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(45)
      },
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(45)
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    ...mapGetters('themeSpecialAndSeries', [
      'themeList',
      'themeTotalCount',
      'themePage',
      'error',
      'formErrors',
      'themeDetail'
    ]),
    ...mapGetters('tabNavigator', [
      'currentTab',
      'currentTabTitle'
    ]),
    sites () {
      return this.$store.getters['site/allEnabledSorted']()
    },
    isSaveButtonVisible () {
      return this.hasPermission(this.requiredPermissions.saveButton)
    },
    isSaveButtonDisabled () {
      return this.theme.siteId === 0 && this.theme.forChildSites === false
    },
    isDeleteButtonVisible () {
      return this.theme.id !== null && this.hasPermission(this.requiredPermissions.deleteButton)
    }
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.prepareDataForSave()
        if (this.editMode) {
          this.$store.dispatch('themeSpecialAndSeries/updateTheme', this.theme)
            .then(() => {
              if (!this.error) {
                NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
                this.theme = this.themeDetail
                this.$router.push('/themeSpecialAndSeries')
              } else {
                NotifyService.setErrorMessage(this.error)
              }
            })
        } else {
          this.$store.dispatch('themeSpecialAndSeries/createTheme', this.theme)
            .then(() => {
              if (!this.error) {
                NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
                this.theme = this.themeDetail
                // this.$router.push('/themeSpecialAndSeries/' + this.theme.id + '/edit')
                this.$router.push('/themeSpecialAndSeries')
              } else {
                NotifyService.setErrorMessage(this.error)
              }
            })
            .catch(error => console.log(error))
        }
      }
    },
    setSpecialheroImage (image) {
      this.theme.heroImage = image[0]
      this.theme.heroImageId = image[0].id
    },
    removeSpecialheroImage () {
      this.theme.heroImage = {}
      this.theme.heroImageId = null
    },
    setSpecialListingImage (image) {
      this.theme.listingImage = image[0]
      this.theme.listingImageId = image[0].id
    },
    removeSpecialListingImage () {
      this.theme.listingImage = {}
      this.theme.listingImageId = null
    },
    deleteTheme () {
      this.$store.dispatch('themeSpecialAndSeries/deleteTheme', this.theme)
        .then(() => {
          if (!this.error) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/themeSpecialAndSeries')
          } else {
            NotifyService.setErrorMessage(this.error)
          }
        })
        .catch(error => console.log(error))
    },
    prepareDataForSave () {
      if (this.theme.heroImage && this.theme.heroImage.id) {
        this.theme.heroImageId = this.theme.heroImage.id
      } else {
        this.theme.heroImageId = null
      }
      if (this.theme.listingImage && this.theme.listingImage.id) {
        this.theme.listingImageId = this.theme.listingImage.id
      } else {
        this.theme.listingImageId = null
      }
    }
  },
  watch: {
    themeDetail: {
      handler (newValue) {
        this.theme = this.themeDetail
      }
    },
    'theme.forChildSites': {
      handler (newValue) {
        if (newValue) {
          this.theme.siteId = 106 // Deník.cz
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.$store.commit('themeSpecialAndSeries/SET_FORM_ERRORS', {})
  }
}

</script>

<style lang="scss" scoped>

.themeSpecialAndSeries-info-header {
  margin: 1rem;
  text-align: right;
  div, h6 {
    display: inline;
  }
  span, h6 {
    margin-right: 1rem;
  }
  h6 {
    color: #bbb;
    font-weight: 100;
  }
}

.theme-new-wrapper {
  button.btn-disabled {
    background-color: #8b8b8b;
    &:hover {
      background-color: #8b8b8b;
    }
  }
}

</style>

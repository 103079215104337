<template>
  <div class="geneea-tag-input">
    <h3> {{ title }} </h3>
    <MultiSelect
      :placeholder="$t('type_keyword')"
      :options="geneeaTags"
      :loading="loading"
      :custom-label="getLabel"
      :limit="999"
      track-by="geneeaId"
      id="geneea-tag-input"
      @search-change="findTags"
      @input="onSelectTag"
    />
    <div v-if="selectedTags.length" class="selected-tags">
      <div v-for="tag in selectedTags" :key="`selected-tags_${tag.id}`" class="selected-tag">
        <span @click="onRemoveTag(tag)">{{ getLabel(tag) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelect from '@/components/form/select/MultiSelect.vue'

export default {
  name: 'GeneeaTagInput',
  props: {
    title: {
      type: String,
      default: ''
    },
    responseTags: {
      type: Array,
      default: () => []
    }
  },
  components: {
    MultiSelect
  },
  data () {
    return {
      selectedTag: null,
      tags: [],
      loading: false,
      geneeaTags: [],
      selectedTags: []
    }
  },
  methods: {
    async findTags (query) {
      if (query.length > 2) {
        await this.$store.dispatch('tag/fetchByTitle', { query, view: 'article' })
        this.geneeaTags = this.$store.getters['tag/list']
      } else {
        this.geneeaTags = []
      }
    },
    getLabel (tag) {
      return `${tag.title} (${tag.type})`
    },
    onSelectTag (selectedTag) {
      if (!this.selectedTags.some(tag => tag.id === selectedTag[0].id)) {
        this.selectedTags.push(selectedTag[0])
        this.$emit('selectedTagsIds', this.getIdsFromTags(this.selectedTags))
      }
    },
    onRemoveTag (removedTag) {
      this.selectedTags = this.selectedTags.filter(tag => tag.id !== removedTag.id)
      this.$emit('selectedTagsIds', this.getIdsFromTags(this.selectedTags))
    },
    getIdsFromTags (tags) {
      return tags.map(tag => tag.id)
    },
    async loadInitialTags () {
      if (this.responseTags.length > 0) {
        this.selectedTags = await this.getTags(this.responseTags)
      } else {
        this.selectedTags = []
      }
      if (this.$refs.multiselectReference) {
        this.$refs.multiselectReference.value = null
      }
    },
    async getTags (tags) {
      if (tags.length > 0) {
        const ids = this.getIdsFromTags(tags)
        try {
          const res = await this.$store.dispatch('tag/loadTagsByIds', ids)
          return res.data
        } catch (error) {
          console.error('Error fetching tags:', error)
          return []
        }
      }
      return []
    }
  },
  watch: {
    responseTags: {
      async handler (newValue) {
        if (newValue.length > 0) {
          this.selectedTags = await this.getTags(newValue)
        } else {
          this.selectedTags = []
        }
      }
    }
  },
  mounted () {
    this.loadInitialTags()
  }
}
</script>

<style lang="scss" scoped>

.geneea-tag-input {
  margin-top: 2rem;
  h3 {
    margin-bottom: 3rem;
  }
  .selected-tags {
    margin-top: 10px;
    .selected-tag span {
      width: fit-content;
      padding: 0 rem(15px);
      margin-bottom: 0.5rem;
      font-weight: 500;
      color: #fff;
      background: #6599FE;
      border-radius: rem(6px);
      cursor: pointer;
      height: rem(40px);
      transition: background 100ms;
      display: flex;
      align-items: center;
      &:hover {
        background: darken(#6599FE, 10%);
      }
    }
  }
}
</style>

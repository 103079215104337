export default {
  title: '',
  id: null,
  forChildSites: false,
  siteId: 0,
  createdAt: null,
  createdById: null,
  description: '',
  heroImage: {},
  heroImageId: null,
  listingImage: {},
  listingImageId: null,
  slug: '',
  tags: [],
  tagIds: [],
  validTo: null,
  meta: {
    title: '',
    description: '',
    keywords: ''
  }
}

<template>
  <ModuleView disable-breadcrumb>

    <!-- Special -->
    <template v-if="currentTab === 'special'" slot="body">
      <SpecialNew v-if="showData" :editMode="showEditComponents"/>
    </template>
    <!-- Theme -->
    <template v-if="currentTab === 'theme'" slot="body">
      <ThemeNew v-if="showData" :editMode="showEditComponents"/>
    </template>
    <!-- Series -->
    <template v-if="currentTab === 'series'" slot="body">
      <SeriesNew v-if="showData" :editMode="showEditComponents"/>
    </template>

  </ModuleView>
</template>

<script>

import { mapGetters } from 'vuex'
import ModuleView from '@/components/ModuleView'
import PermissionService from '@/services/PermissionService'
import ThemeNew from '@/views/themeSpecialAndSeries/newComponents/ThemeNew.vue'
import SeriesNew from '@/views/themeSpecialAndSeries/newComponents/SeriesNew.vue'
import SpecialNew from '@/views/themeSpecialAndSeries/newComponents/SpecialNew.vue'

export default {
  name: 'TopicsAndSpecialsNewView',
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_TAG_PERMISSIONS,
      showEditComponents: false
    }
  },
  components: {
    ThemeNew,
    SeriesNew,
    SpecialNew,
    ModuleView
  },
  validations: {
  },
  computed: {
    ...mapGetters('tabNavigator', ['currentTab'])
  },
  methods: {
    showData () {
      return !this.showEditComponents || (this.showEditComponents && this.dataLoaded)
    },
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    }
  }
}
</script>

<style lang="scss">

.edit-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  span {
    color: #bbb;
    font-weight: 100;
    margin-left: 3rem;
  }
  span:nth-child(1) {
    margin-left: 1rem;
  }
}

</style>

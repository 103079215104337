export default {
  height: 200,
  autoresize_min_height: 200,
  style_formats: [
    {
      title: 'Headers',
      items: [
        { title: 'Header 2', format: 'h2' },
        { title: 'Header 3', format: 'h3' },
        { title: 'Paragraph', format: 'p' }
      ]
    },
    {
      title: 'Inline',
      items: [
        { title: 'Bold', icon: 'bold', format: 'bold' },
        { title: 'Italic', icon: 'italic', format: 'italic' },
        { title: 'Superscript', icon: 'superscript', format: 'superscript' },
        { title: 'Subscript', icon: 'subscript', format: 'subscript' }
      ]
    }
  ],
  toolbar: [
    'undo redo | cut copy paste selectall | styleselect | rubric_headline_2 | custom_headline_2 custom_headline_3 | bold italic | custom_button_unordered_list numlist | link | fullscreen'
  ]
}
